import React from "react";

class Footer extends React.Component {
    constructor (props) {
        super (props);
    }
    render () {
        return (
            <footer className="h-0">
            </footer>
        )
    }
}

export default Footer;